<template>
  <page-content
    :page-title="$t('editMap')"
    :map-name="mapName"
    :map-data="mapData"
    :map-option="mapOption"
    :prognosys-map-type="prognosysMapType"
    :submit-in-progress="submitInProgress"
    @submit="onSubmit"
  />
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { ChartType } from 'astro-chart';

import PageContent from './PageContent';
import { buildRequestParams, buildDataForInputForm } from '@/utils/query-params';

export default {
  name: 'EditMapPage',
  components: {
    PageContent,
  },
  data() {
    return {
      mapOption: 0,
      mapData: {
        map1: null,
        map2: null,
      },
      prognosysMapType: ChartType.SOLAR,
      submitInProgress: false,
    };
  },
  computed: {
    ...mapState('ChartModule', ['requestParams', 'chartId', 'firstPersonData', 'secondPersonData']),
    ...mapGetters('ChartModule', [
      'isDoubleChart',
      'mapName',
      'firstPersonDateShift',
      'secondPersonDateShift',
      'firstPersonCoordsInDegrees',
      'secondPersonCoordsInDegrees',
    ]),
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      const { name } = from;
      if (name === 'chart-history') {
        to.params.to = { name };
      }
    });
  },
  mounted() {
    this.mapData.map1 = buildDataForInputForm({
      ...this.requestParams.firstPersonData,
      dateShift: this.firstPersonDateShift,
      houseSystem: this.firstPersonData.meta.houseSystemConfig.houseSystem,
      ...this.firstPersonCoordsInDegrees,
    });

    if (this.isDoubleChart) {
      this.mapData.map2 = buildDataForInputForm({
        ...this.requestParams.secondPersonData,
        dateShift: this.secondPersonDateShift,
        houseSystem: this.secondPersonData.meta.houseSystemConfig.houseSystem,
        ...this.secondPersonCoordsInDegrees,
      });
    }

    const mapType =
      this.$route.params && this.$route.params.mapOption ? this.$route.params.mapOption : this.requestParams.chartType;

    this.buildMapType(mapType);
  },
  methods: {
    ...mapActions('ChartModule', ['editChart']),
    buildMapType(type) {
      switch (type) {
        case ChartType.NATAL: {
          this.mapOption = 0;
          break;
        }
        case ChartType.SYNASTRY: {
          this.mapOption = 1;
          break;
        }
        case ChartType.SOLAR: {
          this.mapOption = 2;
          this.prognosysMapType = ChartType.SOLAR;
          break;
        }
        case ChartType.TRANSIT: {
          this.mapOption = 2;
          this.prognosysMapType = ChartType.TRANSIT;
          break;
        }
        case ChartType.DIRECTION: {
          this.mapOption = 2;
          this.prognosysMapType = ChartType.DIRECTION;
          break;
        }
        case ChartType.PROGRESSION: {
          this.mapOption = 2;
          this.prognosysMapType = ChartType.PROGRESSION;
          break;
        }
        default: {
          this.mapOption = 0;
          break;
        }
      }
    },
    async onSubmit(data) {
      this.submitInProgress = true;
      if (data.chartType === ChartType.NATAL) {
        data.map2 = {};
      }

      const requestParams = buildRequestParams(data);
      requestParams.chartId = this.chartId;

      await this.editChart(requestParams);
      await this.$router.push({ name: 'natal-chart' });
    },
  },
};
</script>
